import { booleanAttribute, ChangeDetectionStrategy, Component, input } from '@angular/core';

import { LogoModule } from '@pt/components';

import { ControlsComponent } from './components/controls/controls.component';
import { UserProfileComponent } from './components/user-profile/user-profile.component';

@Component({
  standalone: true,
  selector: 'ta-header, [taHeader]',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [LogoModule, ControlsComponent, UserProfileComponent],
})
export class HeaderComponent {
  isShadowVisible = input<boolean, unknown>(false, { transform: booleanAttribute });
}
