import { CanMatchFn, Routes } from '@angular/router';
import { inject } from '@angular/core';

import { AuthService } from '@pt/auth';
import { environment } from '@pt/environment';
import { WINDOW } from '@pinup-teams/common';
import { ngxPermissionsGuard, NgxPermissionsService } from 'ngx-permissions';

import { ShellComponent } from './shell/shell.component';

const signedOutUser: CanMatchFn = () => {
  const authService = inject(AuthService);

  return authService.signedOutCheck();
};

const signedInUser: CanMatchFn = () => {
  const window = inject(WINDOW);
  const authService = inject(AuthService);
  const permissionsService = inject(NgxPermissionsService);

  return authService.signedInCheck().then(urlData => {
    if (urlData === true) {
      const permissions = permissionsService.getPermissions();
      const isAdmin = !!permissions['Admin'];
      if (isAdmin) {
        return urlData;
      }

      window.location.href = environment.lmsHost;

      return false;
    } else {
      return urlData;
    }
  });
};

export const appRoutes: Routes = [
  {
    path: 'signin',
    title: 'Sign In',
    canMatch: [signedOutUser],
    loadComponent: () => import('@pt/auth').then(m => m.SigninComponent),
  },
  {
    path: 'user-not-found',
    title: 'User Not Found',
    loadComponent: () => import('@pt/components').then(m => m.UserNotFoundComponent),
  },
  {
    path: '',
    canMatch: [signedInUser],
    component: ShellComponent,
    children: [
      {
        path: '',
        title: 'Trainee Admin',
        canMatch: [ngxPermissionsGuard],
        data: { permissions: { only: 'Admin', redirectTo: '/user-not-found' } },
        loadChildren: () => import('./pages/admin/admin.module').then(m => m.AdminModule),
      },
      {
        path: '404',
        title: '404',
        loadComponent: () => import('@pt/components').then(m => m.NotFoundComponent),
      },
      {
        path: '**',
        redirectTo: '404',
      },
    ],
  },
];
