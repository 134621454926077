import { createSelector } from '@ngrx/store';
import { PersonalInfo } from '@pt/models';
import { AuthSelectors } from '@pt/auth';

export interface UserProfileViewModel {
  userProfile: PersonalInfo;
}

const selectViewModel = createSelector(
  AuthSelectors.selectProfile,
  (userProfile): UserProfileViewModel => ({ userProfile }),
);

export const UserProfileSelectors = { selectViewModel };
