import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { RouterOutlet } from '@angular/router';

import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { LetDirective } from '@pinup-teams/common';
import { RootState } from '@pt/store';

import { ShellSelectors, ShellViewModel } from './store';
import { HeaderComponent } from './header/header.component';

@Component({
  standalone: true,
  selector: 'ta-shell',
  templateUrl: './shell.component.html',
  styleUrls: ['./shell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [RouterOutlet, AsyncPipe, LetDirective, HeaderComponent],
})
export class ShellComponent {
  private readonly _store = inject(Store<RootState>);
  vm$: Observable<ShellViewModel> = this._store.select(ShellSelectors.selectShellViewModel);
}
