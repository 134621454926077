import { inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';

import { createEffect } from '@ngrx/effects';
import { auditTime, fromEvent, map } from 'rxjs';
import { CommonMediaQueries, MediaScreenService } from '@pinup-teams/common';
import { UiActions } from '@pt/store';

@Injectable()
export class ShellEffects {
  private readonly _document = inject(DOCUMENT);
  private readonly _mediaScreenService = inject(MediaScreenService);

  scrollPosition$ = createEffect(() => {
    let currentScrollPosition = 0;
    const scrollThreshold = this._mediaScreenService.isMatched(CommonMediaQueries.LG) ? 0 : 70;

    return fromEvent(this._document, 'scroll').pipe(
      auditTime(200),
      map((event: Event) => {
        const target = event.target as Document;
        const scroll = target.scrollingElement.scrollTop;
        const isScrollBottom = target.scrollingElement.clientHeight + scroll
          >= target.scrollingElement.scrollHeight;
        const visibilityChangedPayload = { isNavbarHidden: false, isShadowVisible: true };
        visibilityChangedPayload.isNavbarHidden = scroll > scrollThreshold && scroll
          > currentScrollPosition && !isScrollBottom;
        visibilityChangedPayload.isShadowVisible = scroll > scrollThreshold;
        currentScrollPosition = scroll;

        return UiActions.elementsScrollVisibilityChanged(visibilityChangedPayload);
      }),
    );
  });
}
