import { createSelector } from '@ngrx/store';
import { UiSelectors } from '@pt/store';

export interface ShellViewModel {
  isShadowVisibleByScroll: boolean;
}

const selectShellViewModel = createSelector(
  UiSelectors.selectIsShadowVisibleByScroll,
  isShadowVisibleByScroll => ({
    isShadowVisibleByScroll,
  }),
);

export const ShellSelectors = {
  selectShellViewModel,
};
