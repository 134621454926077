import { Component, DestroyRef, HostBinding, inject, OnInit, Renderer2 } from '@angular/core';
import { AsyncPipe, DOCUMENT } from '@angular/common';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { RouterOutlet } from '@angular/router';

import { Store } from '@ngrx/store';
import {
  registerIcons,
  SpinnerDirective,
  SpinnerService,
  PU_ICONS,
  RootActions,
} from '@pinup-teams/common';
import { UiSelectors } from '@pt/store';
import { ALL_ICONS } from 'libs/core/src/lib/icons/generated/icons';

@Component({
  standalone: true,
  selector: 'ta-root',
  template: `
    <ng-container *puSpinner="spinnerService.isSpinning$ | async">
      <router-outlet/>
    </ng-container>
  `,
  imports: [RouterOutlet, AsyncPipe, SpinnerDirective],
})
export class AppComponent implements OnInit {
  readonly spinnerService = inject(SpinnerService);
  private readonly _store = inject(Store);
  private readonly _document = inject(DOCUMENT);
  private readonly _renderer = inject(Renderer2);
  private readonly _destroyRef = inject(DestroyRef);

  @HostBinding('class.ta-root') isRoot = true;

  isDarkTheme$ = this._store.select(UiSelectors.selectIsDarkTheme);

  constructor() {
    registerIcons([...ALL_ICONS, ...PU_ICONS]);
  }

  ngOnInit() {
    this._store.dispatch(RootActions.appInitialized());

    this.isDarkTheme$.pipe(takeUntilDestroyed(this._destroyRef)).subscribe(isDarkTheme => {
      isDarkTheme
        ? this._renderer.addClass(this._document.documentElement, 'pu-dark-theme')
        : this._renderer.removeClass(this._document.documentElement, 'pu-dark-theme');
    });
  }
}
