import { AsyncPipe } from '@angular/common';
import { Component, DestroyRef, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import {
  ButtonModule, IconModule, LetDirective, OkDialog, PopoverDirective,
} from '@pinup-teams/common';
import { AuthActions, AuthService } from '@pt/auth';
import { AvatarModule } from '@pt/components';

import { UserProfileSelectors, UserProfileViewModel } from './store';

@Component({
  standalone: true,
  selector: 'ta-header-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss'],
  imports: [
    LetDirective,
    AsyncPipe,
    AvatarModule,
    ButtonModule,
    IconModule,
    TranslateModule,
    PopoverDirective,
  ],
})
export class UserProfileComponent {
  private readonly _store = inject(Store);
  private readonly _okDialog = inject(OkDialog);
  private readonly _destroyRef = inject(DestroyRef);
  private readonly _authService = inject(AuthService);

  vm$: Observable<UserProfileViewModel> = this._store.select(UserProfileSelectors.selectViewModel);

  logout(): void {
    this._authService.logout();
  }

  remove2fa(): void {
    this._okDialog
      .open({
        headerKey: 'personalInfo.remove2faDialog.header',
        subMessageKey: 'personalInfo.remove2faDialog.message',
        btnOkKey: 'personalInfo.remove2faDialog.yes',
        btnCancelKey: 'personalInfo.remove2faDialog.no',
      })
      .afterClosed()
      .pipe(takeUntilDestroyed(this._destroyRef))
      .subscribe(value => {
        if (value) {
          this._store.dispatch(AuthActions.remove2fa.action());
        }
      });
  }
}
