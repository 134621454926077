import {
  ApplicationConfig, importProvidersFrom, LOCALE_ID, makeEnvironmentProviders,
} from '@angular/core';
import {
  provideRouter, RouteReuseStrategy, withInMemoryScrolling, withRouterConfig,
} from '@angular/router';
import { HttpClient, provideHttpClient, withInterceptors } from '@angular/common/http';
import { provideAnimations } from '@angular/platform-browser/animations';
import { MAT_RIPPLE_GLOBAL_OPTIONS, MatNativeDateModule } from '@angular/material/core';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { NgxPermissionsModule } from 'ngx-permissions';
import { TimeagoModule } from 'ngx-timeago';
import { StoreModule } from '@ngrx/store';
import { routerReducer, StoreRouterConnectingModule } from '@ngrx/router-store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { provideNgxMask } from 'ngx-mask';
import {
  matGlobalRippleConfig,
  PU_CONFIG,
  PU_TOAST_CONFIG,
  PU_TOAST_DEFAULT_CONFIG,
  SPINNER_COMPONENT,
  CustomRouteReuseStrategy,
  TranslateLoaderService,
  SpinnerDirective,
  ToastService,
} from '@pinup-teams/common';
import { KeyboardEffects, RouterEffects } from '@pinup-teams/common';
import { environment } from '@pt/environment';
import { MfeConfig, MfeUrlSegments } from '@pt/mfe';
import { CORE_APP_INITIALIZERS } from '@pt/init';
import { AuthEffects, authReducer, AuthService, AUTH_HTTP_INTERCEPTORS } from '@pt/auth';
import { RootState, UiEffects, uiReducer } from '@pt/store';
import { AnalyticsService } from '@pt/services';
import { SpinnerComponent } from '@pt/components';

import { appRoutes } from './app.routes';

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(
      appRoutes,
      withRouterConfig({ paramsInheritanceStrategy: 'always' }),
      withInMemoryScrolling({ scrollPositionRestoration: 'enabled' }),
    ),
    provideAnimations(),
    makeEnvironmentProviders([
      { provide: MfeConfig, useValue: null },
      { provide: MfeUrlSegments, useValue: new MfeUrlSegments() },
      CORE_APP_INITIALIZERS,
      provideHttpClient(withInterceptors(AUTH_HTTP_INTERCEPTORS)),
      { provide: LOCALE_ID, useValue: 'en' },
      {
        provide: RouteReuseStrategy,
        useClass: CustomRouteReuseStrategy,
      },
      {
        provide: MAT_RIPPLE_GLOBAL_OPTIONS,
        useValue: matGlobalRippleConfig,
      },
      {
        provide: PU_CONFIG,
        useValue: {
          apiHost: environment.apiHost,
          useMocks: environment.useMocks,
          production: environment.production,
          assetsHost: '',
          useNewIcons: true,
        },
      },
      {
        provide: PU_TOAST_CONFIG,
        useValue: PU_TOAST_DEFAULT_CONFIG,
      },
      {
        provide: SPINNER_COMPONENT,
        useValue: SpinnerComponent,
      },
      SpinnerDirective,
      ToastService,
      AnalyticsService,
      provideNgxMask(),
      importProvidersFrom([
        TranslateLoaderService,
        TranslateModule.forRoot({
          defaultLanguage: 'en',
          loader: {
            provide: TranslateLoader,
            useClass: TranslateLoaderService,
            deps: [HttpClient],
          },
        }),
        NgxPermissionsModule.forRoot(),
        TimeagoModule.forRoot(),
        StoreModule.forRoot<RootState>({
          router: routerReducer,
          auth: authReducer,
          ui: uiReducer,
        }),
        EffectsModule.forRoot([RouterEffects, AuthEffects, KeyboardEffects, UiEffects]),
        StoreDevtoolsModule.instrument({
          name: 'Trainee Admin',
          maxAge: 25,
          logOnly: environment.production,
        }),
        StoreRouterConnectingModule.forRoot(),
        MatNativeDateModule,
      ]),
    ]),
    AuthService,
  ],
};
