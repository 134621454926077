import { APP_INITIALIZER } from '@angular/core';

import { registerAnalyticsFactory, registerLocaleFactory } from './factories';

/**
 * Core app initializers
 */
export const CORE_APP_INITIALIZERS = [
  {
    provide: APP_INITIALIZER,
    useFactory: registerLocaleFactory,
    multi: true,
  },
  {
    provide: APP_INITIALIZER,
    useFactory: registerAnalyticsFactory,
    multi: true,
  },
];
