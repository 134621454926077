import { inject } from '@angular/core';

import { MfeConfig } from '@pt/mfe';
import { AnalyticsService } from '@pt/services';

export function registerAnalyticsFactory() {
  const mfeConfig = inject(MfeConfig);

  if (mfeConfig) {
    return (): void => undefined;
  } else {
    const analyticsService = inject(AnalyticsService);

    return () => analyticsService.init();
  }
}
