import { registerLocaleData } from '@angular/common';
import { inject } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs';

export function registerLocaleFactory() {
  const translate = inject(TranslateService);

  return () => translate.use('en').pipe(take(1));
  // It creates lazy chunks with given locales
  // import(
  //   /* webpackInclude: /node_modules\/@angular\/common\/locales\/(en|uk|ru)\.mjs/ */
  //   `node_modules/@angular/common/locales/${service.currentLang || 'en'}.mjs`
  // ).then(module => {
  //   registerLocaleData(module.default);
  //
  //   observer.next();
  //   observer.complete();
  // });
}
