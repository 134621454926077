<ng-container *puLet="isDarkTheme$ | async as isDarkTheme">
  <button
    class="ta-controls__btn-icon"
    type="button"
    puButton
    size="m"
    uiType="quaternary"
    [onlyIcon]="true"
    (click)="setTheme(isDarkTheme)"
  >
    <pu-icon [iconName]="isDarkTheme ? 'light' : 'dark'"/>
  </button>
</ng-container>
