<ng-container *puLet="vm$ | async as vm">
  <div class="ta-shell">
    <header
      taHeader
      [isShadowVisible]="vm.isShadowVisibleByScroll"
    >
    </header>

    <main class="ta-shell__main">
      <div class="ta-shell__content">
        <router-outlet/>
      </div>
    </main>
  </div>
</ng-container>
