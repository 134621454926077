import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';

import { Store } from '@ngrx/store';
import { ButtonModule, IconModule, LetDirective } from '@pinup-teams/common';
import { UiActions, UiSelectors } from '@pt/store';

@Component({
  standalone: true,
  selector: 'ta-header-controls',
  templateUrl: './controls.component.html',
  styleUrls: ['./controls.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [LetDirective, AsyncPipe, ButtonModule, IconModule],
})
export class ControlsComponent {
  private readonly _store = inject(Store);

  isDarkTheme$ = this._store.select(UiSelectors.selectIsDarkTheme);

  setTheme(isDarkTheme: boolean): void {
    this._store.dispatch(UiActions.setTheme({ isDarkTheme: !isDarkTheme }));
  }
}
